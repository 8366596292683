import React from 'react'
import '../App.css'
import {
  Button, 
  Card,
  CardHeader,
  CardBody,
} from "shards-react"
import { Input } from 'antd'
import { API_URL } from '../configs/configs'
import { setTokens } from '../redux/actions/index'
import { connect } from "react-redux"
import notify from '../helpers/notify'

const axios = require('axios')


class Login extends React.Component {
  
    state = {
      loading: false,
      iconLoading: false,
      email: "",
      pwd: ""
    };
    
    enterIconLoading = () => {
      this.setState({ iconLoading: true });
    };

    
    enterLoading = () => {
      this.setState({ loading: true });
      axios.post( API_URL + '/login', {
        
        email: this.state.email,
        pwd: this.state.pwd
    
      })
      .then((response) => {
        let accessToken = response.data.tokens;
        this.props.setTokens({ tokens: accessToken, user: response.data.user });
        this.props.history.push('/general_dashboard')
      })
      .catch((error) => {
        notify("Erro. Verifique as suas credênciais!")
        //console.log("DEU ERRO", error);
        this.setState({ loading: false });

      });
    };
    
    render() {
      return (
        <div className="App">
          <header className="App-header">
            <img src={require('../assets/img/trueEvents-01.png')} alt="TrueEvents" style={{width: "23%", marginBottom: "40px"}}/>
            <Card style={{ width: "350px", backgroundColor: "#fafafa", borderColor: "#b5c7b7", marginBottom:"2%" }}>
              <CardHeader className="shards-card-header">Login</CardHeader>
              <CardBody>
                <Input size="large" style={{marginBottom:10}} placeholder="Email" onChange={(e) => this.setState({ email: e.target.value })} />
                <Input.Password size="large" style={{marginBottom:20}} placeholder="Password" onChange={(e) => this.setState({ pwd: e.target.value })} />
                <Button className="btn-user" size="large" style={{ width: 190 }} loading={this.state.loading} onClick={this.enterLoading} >Login</Button>
              </CardBody>
            </Card>
            <p style={{fontSize:"11px"}}>by <a className="link-login" href="https://www.truenet.pt/">Truenet</a>, Lda.</p>
          </header>
        </div>
      );
    }
  }

  function mapDispatchToProps(dispatch) {
    return {
      setTokens: payload => dispatch(setTokens(payload))
    };
  }
  
  const LoginPage = connect(
    null,
    mapDispatchToProps,
  )(Login);
export default LoginPage;


