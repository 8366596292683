import React from 'react'
import '../App.css'
import { Icon } from 'antd'
import { Card as ShardsCard } from 'shards-react'
import { CardBody as ShardsCardBody } from 'shards-react'
import { CardHeader as ShardsCardHeader } from 'shards-react'
import { ListGroup, ListGroupItem, Col, Row } from 'shards-react'
import { Card, CardContent } from '@material-ui/core'
import { API_URL } from '../configs/configs';
import { connect } from "react-redux";
import { withRouter } from "react-router"
import EditIcon from '@material-ui/icons/Edit'
import Moment from 'react-moment'
import moment from 'moment'
import Avatar from '@material-ui/core/Avatar'

const axios = require('axios');
const _ = require('lodash');


class GeneralDashboard extends React.Component {


	constructor(props) {
		super(props)
		this.state = {
			open: false,
			date: (moment().year() < 2021) ? moment().year(2021) : moment(),
			weddings: [],
			pendingWeddings: []
		}
	}


	changeFormValue = (key, value) => {
		this.setState({
			[key]: value
		})
	}

	increaseYear() {

		let increasedDate = (this.state.date).add(1, 'years')
		this.setState({
			date: increasedDate,
		});

		this.getWeddingsByYear()
	}

	decreaseYear() {

		let decreasedDate = (this.state.date).subtract(1, 'years')

		this.setState({
			date: decreasedDate,
		});

		this.getWeddingsByYear()
	}

	getWeddingsByYear() {

		let year = this.state.date.get('year')

		axios.get(API_URL + '/wedding/getWeddings/' + year, { headers: { 'authorization': this.props.tokens } })
			.then((response) => {
				//console.log("RESPONSE DATA WEDDING", response.data);
				const weddings = response.data.weddings;

				let weddingsArr = weddings.map((value, index) => (
					{
						_id: value._id,
						date: value.date,
						ceremony: value.ceremony,
						groom: value.groom.nameGroom,
						bride: value.bride.nameBride,
						employee: value.employee.name
					}
				))

				this.setState({
					weddings: weddingsArr
				})

			})
			.catch((error) => {
				// notify("Erro na pesquisa de casamentos.")
				console.log(error);
			});
	}

	getPendingWeddings() {

		axios.get(API_URL + '/wedding/getPending/', { headers: { 'authorization': this.props.tokens } })
			.then((response) => {
				//console.log("RESPONSE DATA WEDDING PENDING", response.data);
				const pending = response.data.weddings;
				//console.log("PENDING: ", pending)

				let pendingWeddingsArr = pending.map((value, index) => (
					{
						_id: value._id,
						groom: value.groom.nameGroom,
						bride: value.bride.nameBride,
						employee: value.employee.name
					}
				))

				this.setState({
					pendingWeddings: pendingWeddingsArr
				})

			})
			.catch((error) => {
				// notify("Erro na pesquisa de casamentos.")
				console.log(error);
			});
	}

	componentDidMount = () => {
		this.getWeddingsByYear()
		this.getPendingWeddings()
	}



	render() {
		//("WEDDINGS IN STATE", this.state.weddings)
		let orderedWeddings = _.orderBy(this.state.weddings, ['date'], ['asc'])

		//console.log(this.state.date)

		let weddingsCards = orderedWeddings.map((value, index) => {

			let selectedDate = moment()
			let eventDate = value.date

			return (
				<div style={{ display: "inline-block", maxWidth: "200px", minWidth: "200px", marginRight: "2%", marginBottom: "2%" }}>
					{moment(eventDate).isBefore(selectedDate) ?
						<Card className="pointer" style={{ maxWidth: "200px", minWidth: "200px", marginRight: "2%", marginBottom: "2%", display: "inline-block", backgroundColor: "#FAFAFA" }} onClick={() => { this.props.history.push('/wedding_details/' + value._id) }}>
							<CardContent style={{ textAlign: "-webkit-center" }}>
								{(value.employee === "Maria Luís") ? (
									<div>
										<Avatar style={{ width: "70px", height: "70px", backgroundColor: "#B1DAE6", marginBottom: "20px" }} src="/"><b style={{ fontSize: "15px", fontWeight: "bold" }}>{value.bride.charAt(0)} & {value.groom.charAt(0)}</b></Avatar>
										{value.bride} <br></br> &  <br></br>{value.groom}
									</div>
								) : (
									null
								)}
								{(value.employee === "Mariana") ? (
									<div>
										<Avatar style={{ width: "70px", height: "70px", backgroundColor: "#61858f", marginBottom: "20px" }} src="/"><b style={{ fontSize: "15px", fontWeight: "bold" }}>{value.bride.charAt(0)} & {value.groom.charAt(0)}</b></Avatar>
										{value.bride} <br></br> &  <br></br>{value.groom}
									</div>
								) : (
									null
								)}
								{(value.employee === "Catarina") ? (
									<div>
										<Avatar style={{ width: "70px", height: "70px", backgroundColor: "#AEAAAA", marginBottom: "20px" }} src="/"><b style={{ fontSize: "15px", fontWeight: "bold" }}>{value.bride.charAt(0)} & {value.groom.charAt(0)}</b></Avatar>
										{value.bride} <br></br> &  <br></br>{value.groom}
									</div>
								) : (
									null
								)}
								{(value.employee !== "Maria Luís" && value.employee !== "Mariana" && value.employee !== "Catarina") ? (
									<div>
										<Avatar style={{ width: "70px", height: "70px", backgroundColor: "#2E6171", marginBottom: "20px" }} src="/"><b style={{ fontSize: "15px", fontWeight: "bold" }}>{value.bride.charAt(0)} & {value.groom.charAt(0)}</b></Avatar>
										{value.bride} <br></br> &  <br></br>{value.groom}
									</div>
								) : (
									null
								)}
							</CardContent>
						</Card>
						:
						<Card className="pointer" style={{ maxWidth: "200px", minWidth: "200px", marginRight: "2%", marginBottom: "2%", display: "inline-block" }} onClick={() => { this.props.history.push('/wedding_details/' + value._id) }}>
							<CardContent style={{ textAlign: "-webkit-center" }}>
							{(value.employee === "Maria Luís") ? (
									<div>
										<Avatar style={{ width: "70px", height: "70px", backgroundColor: "#B1DAE6", marginBottom: "20px" }} src="/"><b style={{ fontSize: "15px", fontWeight: "bold" }}>{value.bride.charAt(0)} & {value.groom.charAt(0)}</b></Avatar>
										{value.bride} <br></br> &  <br></br>{value.groom}
									</div>
								) : (
									null
								)}
								{(value.employee === "Mariana") ? (
									<div>
										<Avatar style={{ width: "70px", height: "70px", backgroundColor: "#61858f", marginBottom: "20px" }} src="/"><b style={{ fontSize: "15px", fontWeight: "bold" }}>{value.bride.charAt(0)} & {value.groom.charAt(0)}</b></Avatar>
										{value.bride} <br></br> &  <br></br>{value.groom}
									</div>
								) : (
									null
								)}
								{(value.employee === "Catarina") ? (
									<div>
										<Avatar style={{ width: "70px", height: "70px", backgroundColor: "#AEAAAA", marginBottom: "20px" }} src="/"><b style={{ fontSize: "15px", fontWeight: "bold" }}>{value.bride.charAt(0)} & {value.groom.charAt(0)}</b></Avatar>
										{value.bride} <br></br> &  <br></br>{value.groom}
									</div>
								) : (
									null
								)}
								{(value.employee !== "Maria Luís" && value.employee !== "Mariana" && value.employee !== "Catarina") ? (
									<div>
										<Avatar style={{ width: "70px", height: "70px", backgroundColor: "#2E6171", marginBottom: "20px" }} src="/"><b style={{ fontSize: "15px", fontWeight: "bold" }}>{value.bride.charAt(0)} & {value.groom.charAt(0)}</b></Avatar>
										{value.bride} <br></br> &  <br></br>{value.groom}
									</div>
								) : (
									null
								)}
							</CardContent>
						</Card>
					}
				</div>
			)
		})

		let pendingWeddingsData = this.state.pendingWeddings.map((item, index) => {
			if(item.employee === "Maria Luís"){
				return (
					<ListGroupItem style={{ borderStyle: "solid", borderBottomWidth: "2px", borderTopWidth: "0px", borderBottomColor: "#cecece" }}>
						<Row>
							<Col sm="2">
								<Avatar style={{ width: "40px", height: "40px", backgroundColor: "#B1DAE6" }} src="/"><b style={{ fontSize: "12px", fontWeight: "bold" }}>{item.bride.charAt(0)} & {item.groom.charAt(0)}</b></Avatar>
							</Col>
							<Col sm="9" style={{ textAlign: "left" }}>
								{item.bride} & {item.groom}
							</Col>
							<Col sm="1">
								<i class="m_icon pointer" onClick={() => { this.props.history.push('/wedding_details/' + item._id) }}><EditIcon /></i>
							</Col>
						</Row>
					</ListGroupItem>
				)
			}
			if(item.employee === "Mariana"){
				return (
					<ListGroupItem style={{ borderStyle: "solid", borderBottomWidth: "2px", borderTopWidth: "0px", borderBottomColor: "#cecece" }}>
						<Row>
							<Col sm="2">
								<Avatar style={{ width: "40px", height: "40px", backgroundColor: "#61858F" }} src="/"><b style={{ fontSize: "12px", fontWeight: "bold" }}>{item.bride.charAt(0)} & {item.groom.charAt(0)}</b></Avatar>
							</Col>
							<Col sm="9" style={{ textAlign: "left" }}>
								{item.bride} & {item.groom}
							</Col>
							<Col sm="1">
								<i class="m_icon pointer" onClick={() => { this.props.history.push('/wedding_details/' + item._id) }}><EditIcon /></i>
							</Col>
						</Row>
					</ListGroupItem>
				)
			}
			if(item.employee === "Catarina"){
				return (
					<ListGroupItem style={{ borderStyle: "solid", borderBottomWidth: "2px", borderTopWidth: "0px", borderBottomColor: "#cecece" }}>
						<Row>
							<Col sm="2">
								<Avatar style={{ width: "40px", height: "40px", backgroundColor: "#AEAAAA" }} src="/"><b style={{ fontSize: "12px", fontWeight: "bold" }}>{item.bride.charAt(0)} & {item.groom.charAt(0)}</b></Avatar>
							</Col>
							<Col sm="9" style={{ textAlign: "left" }}>
								{item.bride} & {item.groom}
							</Col>
							<Col sm="1">
								<i class="m_icon pointer" onClick={() => { this.props.history.push('/wedding_details/' + item._id) }}><EditIcon /></i>
							</Col>
						</Row>
					</ListGroupItem>
				)
			}
			if(item.employee !== "Maria Luís" && item.employee !== "Mariana" && item.employee !== "Catarina"){
				return (
					<ListGroupItem style={{ borderStyle: "solid", borderBottomWidth: "2px", borderTopWidth: "0px", borderBottomColor: "#cecece" }}>
						<Row>
							<Col sm="2">
								<Avatar style={{ width: "40px", height: "40px", backgroundColor: "#2E6171" }} src="/"><b style={{ fontSize: "12px", fontWeight: "bold" }}>{item.bride.charAt(0)} & {item.groom.charAt(0)}</b></Avatar>
							</Col>
							<Col sm="9" style={{ textAlign: "left" }}>
								{item.bride} & {item.groom}
							</Col>
							<Col sm="1">
								<i class="m_icon pointer" onClick={() => { this.props.history.push('/wedding_details/' + item._id) }}><EditIcon /></i>
							</Col>
						</Row>
					</ListGroupItem>
				)
			}
			
		})


		return (
			<div className="general_div">
				{(this.state.weddings.length > 0) ? (
					<ShardsCard style={{ width: "95%", textAlign: "center" }}>
						<ShardsCardHeader style={{ backgroundColor: "#f1f1f1" }}>
							<div className="d-flex justify-content-between">
								<h3><Icon type="left" onClick={() => this.decreaseYear()} /></h3>
								<h3><b><Moment format="YYYY">{this.state.date}</Moment></b></h3>
								<h3><Icon type="right" onClick={() => this.increaseYear()} /></h3>
							</div>
						</ShardsCardHeader>
						<ShardsCardBody style={{ display: "inline", paddingTop: "3.5%", paddingLeft: "4%" }}>
							{weddingsCards}
						</ShardsCardBody>
					</ShardsCard>
				) : (
					<ShardsCard style={{ width: "95%", textAlign: "center" }}>
						<ShardsCardHeader style={{ backgroundColor: "#f1f1f1" }}>
							<div className="d-flex justify-content-between">
								<h3><Icon type="left" onClick={() => this.decreaseYear()} /></h3>
								<h3><b><Moment format="YYYY">{this.state.date}</Moment></b></h3>
								<h3><Icon type="right" onClick={() => this.increaseYear()} /></h3>
							</div>
						</ShardsCardHeader>
						<ShardsCardBody style={{ display: "inline", paddingTop: "3.5%", paddingLeft: "4%" }}>
							<Row>
								<p style={{textAlign:"left", marginBottom:"0%"}}>Não existem casamentos agendados para este ano.</p>
							</Row>
						</ShardsCardBody>
					</ShardsCard>
				)}
				{(this.state.pendingWeddings.length > 0) ? (
					<ListGroup className="card" style={{ width: "95%", marginBottom: "5%", marginTop: "5%" }}>
						<ListGroupItem style={{ backgroundColor: "#f1f1f1", borderStyle: "solid", borderBottomWidth: "2px", borderBottomColor: "#f1f1f1", borderTopColor: "#f1f1f1" }}>
							<Row>
								<Col style={{ fontSize: "19px", color: "#212529" }}>
									<b>Casamentos Pendentes</b>
								</Col>
							</Row>
						</ListGroupItem>
						{pendingWeddingsData}
					</ListGroup>
				) : (
					<ListGroup className="card" style={{ width: "95%", marginBottom: "5%", marginTop: "5%" }}>
						<ListGroupItem style={{ backgroundColor: "#f1f1f1", borderStyle: "solid", borderBottomWidth: "2px", borderBottomColor: "#f1f1f1", borderTopColor: "#f1f1f1" }}>
							<Row>
								<Col style={{ fontSize: "19px", color: "#212529" }}>
									<b>Casamentos Pendentes</b>
								</Col>
							</Row>
						</ListGroupItem>
						<ListGroupItem style={{ borderStyle: "solid", borderBottomWidth: "2px", borderTopWidth: "0px", borderBottomColor: "#cecece" }}>
							<Row>
								<p style={{marginBottom:"0%"}}>Não existem casamentos pendentes.</p>
							</Row>
						</ListGroupItem>
					</ListGroup>
				)}
			</div>

		)
	}
}


let appContainer = withRouter(GeneralDashboard);

function mapStateToProps(state) {
	return { tokens: state.tokens, reduxStorageLoaded: state.storageLoaded, validTokens: state.validTokens, _id: state.user._id }
}

const AppRedux = connect(
	mapStateToProps,
)(appContainer);

export default AppRedux;