import React from 'react';
import '../App.css';
import { Button, Collapse, Checkbox } from 'antd';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import AddIcon from '@material-ui/icons/NoteAdd';
import PdfIcon from '@material-ui/icons/PictureAsPdf';
import ArrowUpIcon from '@material-ui/icons/ArrowUpward';
import MeetingIcon from '@material-ui/icons/EventNote';
import { Card as ShardsCard } from 'shards-react';
import { CardBody as ShardsCardBody } from 'shards-react';
import { CardHeader as ShardsCardHeader } from 'shards-react';
import { Button as ButtonShards } from 'shards-react';
import { Col, Row, Modal, ModalBody, ModalHeader, ModalFooter, FormTextarea } from 'shards-react';
import { Card, CardContent } from '@material-ui/core';
import { HorizontalBar } from 'react-chartjs-2';
import ReactMinimalPieChart from 'react-minimal-pie-chart';
import { API_URL } from '../configs/configs';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Moment from 'react-moment';
import notify from '../helpers/notify';
import {saveAs} from 'file-saver';
import Tooltip from '@material-ui/core/Tooltip';
import Fade from '@material-ui/core/Fade';
import { after as _after } from 'lodash';
import WebIcon from '@material-ui/icons/Language';
import { GoogleOutlined } from '@ant-design/icons';
import CanvasJSReact from '../assets/canvasjs.react';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';


var CanvasJSChart = CanvasJSReact.CanvasJSChart;
const axios = require('axios');
const { Panel } = Collapse;


class WeddingDetails extends React.Component {
    state = {
        wedding:[{}],
        quizSentDate: null,
        quizSentState: false,
        categoriesArr: [],
        wantedCategories: [],
        openDelete: false,
        selectedFile: null,
        chartData: {},
        collapse: false,
        valuesGraph: []    
    }

    onClickTest(){
        console.log(" - CLICKED - ")
    }
    toggleDelete = (openDelete = false) => {
        this.setState({
          openDelete: openDelete
        })
    }
    toggleUpload = (openUpload = false) => {
        this.setState({
          openUpload: openUpload
        })
    }
    toggleCollapse = (collapse = false) => {
        this.setState({
          collapse: !this.state.collapse
        })
    }

    clicked = (cat = null, isWanted = null) => {
        let id = this.props.match.params.id;
        let doc = {
            category: cat,
            isWanted: isWanted
        }

        let step = _after(1, () => {
            this.getWedingInfo()
            this.generatePlanningGraph()
        })

        axios.put( API_URL + '/wedding/updateCategoryWanted/' + id, doc, { headers: {authorization: this.props.tokens} })
        .then((response) => {
            //console.log(response.data);
            if(isWanted === false){
                notify("Categoria " + cat + ' alterada para "Não Contratar".', "success")
            } else {
                notify("Categoria " + cat + ' alterada para "Contratar".', "success")
            }

            step()
        })
        .catch((error) => {
            console.log(error);
            step()
        });
    }

    onChangeHandler=event=>{
        //console.log("EVENT TARGET FILES: ", event.target.files[0]);
        this.setState({
          selectedFile: event.target.files[0],
          loaded: 0,
        })
    }

    generatePlanningGraph = () => {
        let auxiliarWanted = []
        let auxiliarUnwanted = []
        let arr = []
        let dateArr = []

        let backgroundColors = [
            'rgba(205, 29, 139, 0.2)',
            'rgba(255, 159, 64, 0.2)',
            'rgba(255, 205, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(201, 203, 207, 0.2)',
            'rgba(112, 74, 28, 0.2)',
            'rgba(166, 166, 0, 0.2)',
            'rgba(0, 53, 166, 0.2)',
            'rgba(97, 0, 166, 0.2)',
            'rgba(166, 0, 50, 0.2)',
            'rgba(212, 147, 17, 0.2)',
            'rgba(17, 212, 95, 0.2)',
            'rgba(93, 76, 145, 0.2)',
            'rgba(235, 231, 14, 0.2)',
            'rgba(17, 206, 212, 0.2)',
            'rgba(224, 159, 191, 0.2)',
            'rgba(255, 182, 87, 0.2)',
            'rgba(77, 46, 255, 0.2)',
            'rgba(29, 115, 29, 0.2)'
        ]

        let borderColors = [
            'rgb(205, 29, 139)',
            'rgb(255, 159, 64)',
            'rgb(255, 205, 86)',
            'rgb(75, 192, 192)',
            'rgb(54, 162, 235)',
            'rgb(153, 102, 255)',
            'rgb(201, 203, 207)',
            'rgb(112, 74, 28)',
            'rgb(166, 166, 0)',
            'rgb(0, 53, 166)',
            'rgb(97, 0, 166)',
            'rgb(166, 0, 50)',
            'rgb(212, 147, 17)',
            'rgb(17, 212, 95)',
            'rgb(93, 76, 145)',
            'rgb(235, 231, 14)',
            'rgb(17, 206, 212)',
            'rgb(224, 159, 191)',
            'rgb(255, 182, 87)',
            'rgb(77, 46, 255)',
            'rgb(29, 115, 29)'
        ]

        this.state.categoriesArr.map((value, index) => {
            if(value.isWanted === true){
                auxiliarWanted.push(value)
            } else {
                auxiliarUnwanted.push(value)
            }
            this.setState({
                wantedCategories: auxiliarWanted,
                unwantedCategories: auxiliarUnwanted,
            })
        })

        this.state.wantedCategories.map((value, index) => {
            arr.push(value.cat)
            dateArr.push(value.daysUntil)
        })

        let aux = {
            labels: arr,
            datasets: [
                {
                label: 'Dias restantes para fecho',
                backgroundColor: backgroundColors,
                borderColor: borderColors,
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                hoverBorderColor: 'rgba(255,99,132,1)',
                data: dateArr
                }
            ]
        }

        this.setState({
            chartData: aux
        })
    }

    getWedingInfo = () => {
        let id = this.props.match.params.id;

        axios.get( API_URL + '/wedding/getWedding/' + id , { headers: {'authorization': this.props.tokens} })
        .then((response) => {
            //console.log("RESPONSE DATA WEDDING BY ID", response.data.wedding);
            let weddingRes = response.data.wedding
            let wedding = response.data

            let weddingData = 
                {
                    date: weddingRes.date,
                    numGuests: weddingRes.numGuests,
                    location: weddingRes.location,
                    budget: weddingRes.budget,
                    _id: weddingRes._id,
                    ceremony: weddingRes.ceremony,
                    groom: weddingRes.groom.nameGroom,
                    bride: weddingRes.bride.nameBride,
                    categories: weddingRes.categories,
                    employee: weddingRes.employee.name,
                    emailIsSentState: weddingRes.emailIsSent.state,
                    emailIsSentDate: weddingRes.emailIsSent.date,
                    contract: weddingRes.contract,
                    daysUntil: weddingRes.daysUntil,
                    webSiteUrl: weddingRes.webSiteUrl,
                    docsUrl: weddingRes.docsUrl,
                    allClosed: wedding.areAllClosed,
                    finalTasks: weddingRes.finalTasks
                }
       

            this.setState({
                wedding: weddingData,
                weddingNotes: weddingRes.generalNotes,
                categoriesArr: weddingRes.categories
            })
            //console.log("State: ", this.state)
            this.generatePlanningGraph()
        })
        .catch((error) => {
            console.log(error);
        });

    }

    componentDidMount = () => {

        this.getWedingInfo()

        let id = this.props.match.params.id;

        axios.get( API_URL + '/wedding/negotiationValues/' + id , { headers: {'authorization': this.props.tokens} })
        .then((response) => {
            console.log("RESPONSE DATA VALS FROM API", response.data.valuesArray);
            
            this.setState({
                valuesGraph: response.data.valuesArray
            })
          
        })
        .catch((error) => {
            console.log(error);
        });

    }

    deleteWedding = () => {
        let wedding_id = this.props.match.params.id;
        axios.delete( API_URL + '/wedding/deleteWedding/' + wedding_id, { headers: {'authorization': this.props.tokens} })
        .then((response) => {
            this.setState({ 
                loading: false,
            })
            notify("Casamento eliminado com sucesso!", "success")
            this.props.history.push('/general_dashboard')
        })
        .catch((err) => {
            notify("Erro ao eliminar casamento.")
            console.dir(err);
            this.setState({ 
                loading: false
            })
        });
    }

    upload = () => {     
        let wedding_id = this.props.match.params.id;
        const form_data = new FormData();
        form_data.append('file', this.state.selectedFile);
        axios.post( API_URL + '/files/uploadContract/' + wedding_id, form_data, { headers: {"authorization": this.props.tokens } })
        .then((response) => {
            //console.log(response.data);
            notify("Upload efetuado com sucesso!", "success")           
            window.location.reload()
        })
        .catch((error) => {
            notify("Erro ao fazer upload do contrato.")
            console.log(error);
            this.setState({ loading: false });
        });
    };

    download = () => {
        let wedding_id = this.props.match.params.id;

        axios.get( API_URL + '/files/downloadContract/' + wedding_id, { headers: {Authorization: this.props.tokens}, responseType: 'blob', parms: null })
        .then((res)=> {
            //const pdfBlob = new Blob([res.data], {type: 'application/pdf'});
            notify("Visualização do contrato efetuada com sucesso!", "success")
            //console.log("bro - ", res)
            //Build a URL from the file
            const fileURL = URL.createObjectURL(res.data);
            console.log("FILE URL: ", fileURL)
            //console.log("LENGTH: ", pdfBlob)
            //saveAs(pdfBlob, wedding_id + '.pdf');
            window.open(fileURL);
          })
        .catch((error) => {
            notify("Erro na visualização.")
            console.log(error);
        });
    }

    
    sendQuiz = () => {
        let wedding_id = this.props.match.params.id;

        axios.post( API_URL + '/email/sendQuiz/' + wedding_id , null, { headers: {'authorization': this.props.tokens} })
        .then((response) => {
            this.setState({ 
                loading: false,
            })
            notify("Questionário enviado com sucesso!", "success")
            this.getWedingInfo()
        })
        .catch((err) => {
            notify("Erro ao enviar questionário.")
            console.dir(err);
            this.setState({ 
                loading: false
            })
        });
    }

    //ON CHANGE CHECKLIST   
	changeChecklist = (key, checked) => {
		console.log("key", key)
		console.log("value", checked)
		

		//GET URL PARAMS
		let wedding_id = this.props.match.params.id;

		let obj = {
			isChecked: checked,
			task: key
		}

		//UPDATE IS CHECKED
		axios.put(API_URL + '/wedding/updateFinalTaskChecked/' + wedding_id, obj, { headers: { Authorization: this.props.tokens }})
        .then((response) => {
            notify("Checklist alterada com sucesso!", "success")
            this.componentDidMount()
        })
        .catch((error) => {
            console.log(error);
            notify("Erro ao alterar a checklist.")
        });
		
    }
    
    //ON CHANGE NOTES
    handleNotesText(key, value) {
		this.setState({ 
			[key]: value 
		});

		console.log("NOTES: ", this.state)
    }
    
    //SAVE NOTES
    saveNotes = () => {
        let wedId = this.props.match.params.id

        let obj = {
            generalNotes: this.state.weddingNotes
        }
		
		//UPDATE FORNECEDORES EM NEGOCIAÇÃO - AXIOS
		axios.put(API_URL + '/wedding/updateGeneralNotes/' + wedId, obj, { headers: { Authorization: this.props.tokens } })
		.then((response) => {
			notify("Notas gravadas com sucesso!", "success")
		})
		.catch((error) => {
			console.log(error);
			notify("Erro ao gravar notas.")
		});

    }
    

    render() {

        let wedNames = this.state.wedding.bride + "&" + this.state.wedding.groom
        let fileName = "BudgetGeral_" + wedNames
        let arrayVals = []
        this.state.valuesGraph.map((itm, index) => {
            if(itm.cat === "Total Gasto" || itm.cat === "Quota Extra") {
                arrayVals.push({ 
                    name: itm.cat,
                    y: itm.value
                })
            }
            console.log("PRINT ARRAY PUSHED, ", arrayVals)
        })
        console.log("PRINT ARRAY PUSHED, ", arrayVals)

        const options = {
			animationEnabled: true,
			exportFileName: fileName,
            exportEnabled: true,
            height: 280,
            colorSet: "colorSet4",
            subtitles: [{
				text: this.state.wedding.budget + "€",
				verticalAlign: "center",
				fontSize: 22,
				dockInsidePlotArea: true
			}],
            legend: {
                horizontalAlign: "right",
                verticalAlign: "center",
                maxWidth: 500,
                itemWidth: 150,
                markerMargin: 10
            },
			data: [{
				type: "doughnut",
				showInLegend: true,
				legendText: "{name}: {y}€",
				toolTipContent: "{name}: {y}€",
				//indexLabel: "{name}",
				dataPoints: arrayVals
			}]
        }
        

        console.log("wed: ", this.state.wedding)
        let wedId = this.props.match.params.id

        let wantedCategories = this.state.categoriesArr.map((value, index) => {
            if(value.isWanted === true && value.isClosed === false){
                return (
                    <div style={{marginLeft:"15px", marginRight:"15px", display:"inline-block", justifyContent:"space-evenly"}}>
                        <Button className="btn-badge btn-badge-styles" shape="circle" size="small" onClick={() => this.clicked(value.cat, false)}>X</Button>
                        <Card className="supplier-card-styles pointer" onClick={() => { this.props.history.push('/category_providers/?wed=' + wedId + '&cat=' + value.cat) }}>
                            <CardContent className="supplier-card-content-styles">
                                {value.cat}
                            </CardContent>
                        </Card>
                    </div>
                )
            }
        })

        let closedCategories = this.state.categoriesArr.map((value, index) => {
            if(value.isClosed === true){
                return (
                    <div style={{marginLeft:"15px", marginRight:"15px", marginTop:"20px", display:"inline-block", justifyContent:"space-evenly"}}>
                        <Card className="supplier-card-styles-closed pointer" onClick={() => { this.props.history.push('/category_providers/?wed=' + wedId + '&cat=' + value.cat) }}>
                            <CardContent className="supplier-card-content-styles-closed">
                                {value.cat}
                            </CardContent>
                        </Card>
                    </div>
                )
            }
        })

        let unwantedCategories = this.state.categoriesArr.map((value, index) => {
            if(value.isWanted === false && value.isClosed === false){
                return (
                    <div style={{marginLeft:"15px", marginRight:"15px", display:"inline-block"}}>
                        <Button className="btn-badge-unwanted btn-badge-styles" shape="circle" size="small" onClick={() => this.clicked(value.cat, true)}><ArrowUpIcon style={{fontSize:"14px"}}></ArrowUpIcon></Button>
                        <Card className="unwanted-supplier-card-styles">
                            <CardContent className="supplier-card-content-styles">
                                {value.cat}
                            </CardContent>
                        </Card>
                    </div>
                )
            }
        })

        let wantedCount = 0
        for (const item of wantedCategories) {
            if(item === undefined || item === null){
                wantedCount++
            }
        }

        let closedCount = 0
        for (const item of closedCategories) {
            if(item === undefined || item === null){
                closedCount++
            }
        }

        let unwantedCount = 0
        for (const item of unwantedCategories) {
            if(item === undefined || item === null){
                unwantedCount++
            }
        }

        

        return(
            <div className="general_div">
                <h5 style={{color:"#66CCE4", marginBottom:"4%"}}><b>{this.state.wedding.bride} & {this.state.wedding.groom}</b></h5>
                <Row style={{justifyContent:"space-evenly"}}>
                    <ShardsCard className="card-info" style={{float:"left"}}>
                        <ShardsCardHeader className="card-info-header">
                            <div style={{alignContent:"center"}}>
                                <h6><b>Informações Casamento</b></h6>
                            </div>
                        </ShardsCardHeader>
                        <ShardsCardBody className="card-info-body">
                            <Row style={{marginBottom:"30px"}}>
                                {(this.state.wedding.contract !== null && this.state.wedding.contract !== undefined) ? (
                                    <div>
                                        <Col className="inline-col" style={{justifyContent:"flex-end"}}>
                                            <Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Upload Contrato" placement="top">
                                                <AddIcon style={{marginRight:"6%"}} className="btn-add pointer" onClick={() => this.toggleUpload(true)}/>
                                            </Tooltip>
                                            <Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Visualizar Contrato" placement="top">
                                                <PdfIcon style={{marginLeft:"6%"}} className="pointer" onClick={this.download}/>
                                            </Tooltip>
                                        </Col>
                                    </div>
                                ) : (
                                    <Col className="inline-col" style={{justifyContent:"flex-start"}}>
                                        <Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Upload Contrato" placement="top">
                                            <AddIcon className="btn-add pointer" onClick={() => this.toggleUpload(true)}/>
                                        </Tooltip>
                                    </Col>
                                )}
                                <Col className="inline-col" style={{justifyContent:"flex-end"}}>
                                    <Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Reuniões" placement="top">
                                        <MeetingIcon style={{marginRight:"3%"}} className="pointer" onClick={() => { this.props.history.push('/meetings/' + this.props.match.params.id)}}/>
                                    </Tooltip>
                                    <Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Editar Casamento" placement="top">
                                        <EditIcon style={{marginRight:"2%"}} className="pointer" onClick={() => { this.props.history.push('/edit_wedding/' + this.props.match.params.id)}}/>
                                    </Tooltip>
                                    <Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 200}} title="Apagar Casamento" placement="top">
                                        <DeleteIcon style={{marginLeft:"1%"}} className="btn-delete pointer" onClick={() => this.toggleDelete(true)}/>
                                    </Tooltip>
                                </Col>
                            </Row>
                            <Row style={{marginBottom:"15px"}}>
                                <Col className="inline-col">
                                    <p className="p-title-label">Data: </p>
                                    <p className="p-label">
                                        {(this.state.wedding.date) ? (
                                            <p className="p-label"><Moment format="DD-MM-YYYY">{this.state.wedding.date}</Moment></p>
                                        ) : 
                                            <p className="p-label">Por agendar.</p>
                                        }
                                    </p>
                                </Col>
                                <Col className="inline-col">
                                    <p className="p-title-label">Cerimónia: </p>
                                    <p className="p-label">{this.state.wedding.ceremony}</p>
                                </Col>
                            </Row>
                            <Row style={{marginBottom:"20px"}}>
                                <Col className="inline-col">
                                    <p className="p-title-label">N.º Convidados: </p>
                                    <p className="p-label"> {this.state.wedding.numGuests} </p>
                                </Col>
                                <Col className="inline-col">
                                    <p className="p-title-label">Local: </p>
                                    <p className="p-label"> {this.state.wedding.location} </p>
                                </Col>
                            </Row>
                            {(this.state.wedding.webSiteUrl !== null && this.state.wedding.webSiteUrl !== undefined && this.state.wedding.webSiteUrl !== "") ? (
                                <Row style={{marginBottom:"15px"}}>
                                    <Col sm="12" style={{textAlignLast:"center"}}>
                                        <a href={this.state.wedding.webSiteUrl} target="_blank"><p className="p-title-label"><WebIcon style={{fontSize:"17px", marginRight:"8px"}}/>Visualizar Website</p></a>
                                        {/* <p className="p-label">{this.state.wedding.webSiteUrl}</p> */}
                                    </Col>
                                </Row>
                            ) : (
                                <Row></Row>
                            )}
                            {(this.state.wedding.docsUrl !== null && this.state.wedding.docsUrl !== undefined && this.state.wedding.docsUrl !== "") ? (
                                <Row style={{marginBottom:"30px"}}>
                                    <Col sm="12" style={{textAlignLast:"center"}}>
                                        <a href={this.state.wedding.docsUrl} target="_blank"><p className="p-title-label"><GoogleOutlined style={{fontSize:"15px", verticalAlign:"text-bottom", marginRight:"8px"}}/>Google Drive (Docs extra)</p></a>
                                    </Col>
                                </Row>
                            ) : (
                                <Row></Row>
                            )}
                            <Row style={{marginBottom:"10px"}}>
                                <Col className="inline-col">
                                    <p className="p-title-label">Coordenadora: </p>
                                    <p className="p-label">{this.state.wedding.employee}</p>
                                </Col>
                            </Row>
                            <Row style={{marginBottom:"8px", marginTop:"40px"}}>
                                <Col className="inline-col" style={{justifyContent:"center"}}>
                                    <ButtonShards className="btn-inverted" onClick={this.sendQuiz}>Enviar Questionário</ButtonShards>
                                </Col>
                                <Col className="inline-col" style={{justifyContent:"center"}}>
                                    <ButtonShards className="btn-inverted" onClick={() => { this.props.history.push('/insert_quiz/' + this.props.match.params.id)}} >Inserir Questionário</ButtonShards>
                                </Col>
                            </Row>
                            {(this.state.wedding.emailIsSentState === true) ? (
                                <Row style={{marginTop:"10px", marginBottom:"2%"}}>
                                    <Col className="inline-col">
                                        <p className="p-mini-label">Enviado a: <Moment format="DD-MM-YYYY HH:mm">{this.state.wedding.emailIsSentDate}</Moment></p>
                                    </Col>
                                </Row>
                            ) : null}
                            {/* GERAR ALINHAMENTO */}
                            <Row style={{marginBottom:"5px", marginTop:"2%"}}>
                                <Col className="inline-col" style={{justifyContent:"center"}}>
                                    <ButtonShards className="btn-inverted" onClick={() => { this.props.history.push('/generate_alignment/' + this.props.match.params.id)}} >Gerar Alinhamento</ButtonShards>
                                </Col>
                            </Row>
                        </ShardsCardBody>
                    </ShardsCard>
                    <ShardsCard className="card-info" style={{float:"right"}}>
                        <ShardsCardHeader className="card-info-header">
                            <div style={{alignContent:"center"}}>
                                <h6><b>Budget</b></h6>
                            </div>
                        </ShardsCardHeader>
                        <ShardsCardBody className="card-info-body">
                            {/* <Row style={{marginRight:"15px", marginBottom:"10px"}}>
                                <Col className="inline-col">
                                    <p className="p-title-label">Budget </p>
                                    <p className="p-label"> {this.state.wedding.budget}€</p>
                                </Col>
                            </Row> */}
                            {(this.props.user.role && (this.props.user.role.indexOf("admin") !== -1) ) ? (
                                <Row style={{justifyContent:"flex-end", marginBottom:"-5px", marginRight:"-3px"}}>
                                    <p className="pointer" style={{fontSize:"12px", color:"#000000"}} onClick={() => { this.props.history.push('/commissions/' + this.props.match.params.id)}}><b><EuroSymbolIcon style={{fontSize:"20px"}}></EuroSymbolIcon></b></p>
                                </Row>
                            ) :  null}
                            <Row style={{justifyContent:"center", marginBottom:"15px"}}>
                                <CanvasJSChart options = {options}/>
                            </Row>
                            <Row style={{justifyContent:"center", marginBottom:"-15px"}}>
                                <p className="pointer" style={{fontSize:"12px", color:"#000000"}} onClick={() => { this.props.history.push('/statistics/' + this.props.match.params.id)}}><b><u>Mais Estatísticas</u></b></p>
                            </Row>
                            {/* <Row style={{justifyContent:"center", marginBottom:"-15px"}}>
                                <p className="pointer" style={{fontSize:"12px", color:"#000000"}} onClick={() => { this.props.history.push('/commissions/' + this.props.match.params.id)}}><b><u>Comissões</u></b></p>
                            </Row> */}
                        </ShardsCardBody>
                    </ShardsCard>
                </Row>
                <Row style={{justifyContent:"space-evenly", marginTop:"4%"}}>
                    <h5 style={{color:"#66CCE4", marginBottom:"4%"}}><b>Planeamento</b></h5>
                </Row>
                <Row style={{justifyContent:"space-evenly"}}>
                    <Collapse accordion style={{width:"80%", justifyContent:"space-evenly"}}>
                        <Panel header="Tempo ideal de planeamento" key="1">
                            {(this.state.wedding.date) ? (
                                <div>
                                    <Row style={{ marginTop:"2%", marginBottom:"0%", justifyContent:"center"}}>
                                        <p style={{fontSize:"12px"}}>Faltam {this.state.wedding.daysUntil} dias para o casamento.</p>
                                    </Row>
                                    <HorizontalBar 
                                        data={this.state.chartData}
                                        width={100}
                                        height={370}
                                        options={{
                                            maintainAspectRatio: false,
                                            scales: {
                                                xAxes: [{
                                                    ticks: {
                                                        beginAtZero: true
                                                    }
                                                }]
                                            }
                                        }}
                                    />
                                </div>
                            ) : (
                                <p style={{marginTop:"2%"}}>Como não existe data agendada para o casamento, não é possível calcular o tempo ideal de planeamento.</p>
                            )}
                        </Panel>
                    </Collapse>
                </Row>
                <Row style={{marginLeft:"8%", marginTop:"5%"}}>
                    <Col className="inline-col">
                        <p style={{ textAlign: "left", fontSize: "15px" }}><b>Notas</b></p>
                    </Col>
                </Row>
                <Row style={{marginLeft:"9%", marginBottom:"1%"}}>
                    <FormTextarea id="text-area" value={this.state.weddingNotes} style={{ width: "90%", minHeight: "130px", fontSize: "13px" }} onChange={(el) => { this.handleNotesText("weddingNotes", el.target.value) }} />
                </Row>
                <Row style={{marginLeft:"9%", marginBottom:"1%", marginRight:"8%", justifyContent:"flex-end", float:"right", width:"90%"}}>
                    <ButtonShards className="btn-user" onClick={this.saveNotes}>Gravar Notas</ButtonShards>
                </Row>
                {(this.state.wedding.allClosed === true) ? (
                    <div style={{textAlign:"left"}}>
                        <Row style={{marginLeft:"9%", marginBottom:"2%", marginTop:"5%"}}>
                            <p className="p-title-label-lg">Checklist </p>
                        </Row>
                        <Row style={{justifyContent:"space-evenly", marginTop:"1%"}}>
                            <Collapse accordion style={{width:"80%", justifyContent:"space-evenly"}}>
                                <Panel header="Tarefas Finais" key="1">
                                    {(this.state.wedding.finalTasks !== null && this.state.wedding.finalTasks !== undefined) ? (
                                        this.state.wedding.finalTasks.map((item, index) => {
                                            return(
                                                <div>
                                                    <Checkbox checked={item.isChecked} style={{marginBottom:"1%"}} onChange={(e) => { this.changeChecklist(item.task, e.target.checked)}}> {item.task}</Checkbox>
                                                </div>
                                            )	
                                        })
                                    ) : (
                                        <p style={{ marginTop: "2%" }}>Não existe checklist final.</p>
                                    )}
                                </Panel>
                            </Collapse>
                        </Row>
                    </div>
                ) : (
                    <Row></Row>
                )}
                {(wantedCount !== wantedCategories.length) ? (
                    <div>
                        <Row style={{marginLeft:"9%", marginBottom:"2%", marginTop:"5%"}}>
                            <p className="p-title-label-lg">Contratar </p>
                        </Row>
                        <Row style={{width:"80%", marginBottom:"1%", justifyContent:"space-evenly"}}>
                            <Col style={{display: "inline", paddingTop:"2%"}}>
                                {wantedCategories}
                            </Col>
                        </Row>
                    </div>
                ) : (
                    <Row></Row>
                )}
                {(closedCount !== closedCategories.length) ? (
                    <div>
                        <Row style={{marginLeft:"9%", marginBottom:"1%", marginTop:"5%"}}>
                            <p className="p-title-label-lg">Tratado </p>
                        </Row>
                        <Row style={{width:"80%", marginBottom:"1%", justifyContent:"space-evenly"}}>
                            <Col style={{display: "inline", paddingTop:"2%"}}>
                                {closedCategories}
                            </Col>
                        </Row>
                    </div>
                ) : (
                    <Row></Row>
                )}
                {(unwantedCount !== unwantedCategories.length) ? (
                    <div>
                        <Row style={{marginLeft:"9%", marginTop:"6%" , marginBottom:"1%"}}>
                            <p className="p-title-label-lg">Não Contratar </p>
                        </Row>
                        <Row style={{width:"80%", marginBottom:"1%", justifyContent:"space-evenly"}}>
                            <Col style={{display: "inline", paddingTop:"2%"}}>
                                {unwantedCategories}
                            </Col>
                        </Row>
                    </div>
                ) : (
                    <Row></Row>
                )}
 
                <Modal open={this.state.openUpload} toggle={() => { this.toggleUpload(false) }}>
                    <ModalHeader>Upload Contrato</ModalHeader>
                    <ModalBody>
                    {(this.state.wedding.contract !== null) ? (
                        <div>
                            <Row style={{marginBottom:"20px", display:"inline-flex"}}>
                                <h6 style={{color:"#b5291d"}}>Atenção!</h6>
                            </Row>
                            <Row style={{marginBottom:"30px"}}>
                                <p className="p-label">
                                    Já existe um contrato para este casamento. Ao fazer upload de um novo, o atual será eliminado.
                                    Recorde-se que o formato terá de ser pdf.
                                </p>         
                            </Row>
                            <Row style={{justifyContent:"flex-end"}}>
                                <input id="file-upload" type="file" onChange={this.onChangeHandler}/>
                            </Row>
                        </div>
                    ) : (
                        <div>
                            <Row style={{marginBottom:"30px"}}>
                                <p className="p-label">Selecione o ficheiro do contrato. Recorde-se que o formato terá de ser pdf.</p>         
                            </Row>
                            <Row style={{justifyContent:"flex-end"}}>
                                <input id="file-upload" type="file" onChange={this.onChangeHandler}/>
                            </Row>
                        </div>                
                    )}
                    </ModalBody>
                    <ModalFooter>
                        <Button className="btn-inverted" onClick={() => this.toggleUpload(false)}>Cancelar</Button>
                        <Button className="btn-inverted" onClick={this.upload}>Upload</Button> 
                    </ModalFooter>
                </Modal>
                <Modal open={this.state.openDelete} toggle={() => { this.toggleDelete(false) }}>
                    <ModalHeader>Atenção!</ModalHeader>
                    <ModalBody>Este passo é irreversível. Deseja eliminar?</ModalBody>
                    <ModalFooter>
                        <Button className="btn-inverted" onClick={() => this.toggleDelete(false)}>Cancelar</Button>
                        <Button className="btn-inverted" onClick={this.deleteWedding}>Apagar</Button> 
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

let appContainer = withRouter(WeddingDetails);
  function mapStateToProps(state) {
    return { tokens: state.tokens, reduxStorageLoaded: state.storageLoaded, validTokens: state.validTokens, _id: state.user._id, user: state.user }
  }
  const AppRedux = connect(
    mapStateToProps,
  )(appContainer);
  
export default AppRedux;