import React from 'react';
import '../App.css';
import { Col, Row, Button, FormTextarea } from "shards-react";
import { TimePicker, Input } from 'antd';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { API_URL } from '../configs/configs';
import notify from '../helpers/notify';
import moment from 'moment';
import ArrowBack from '@material-ui/icons/ArrowBackIos';
import {saveAs} from 'file-saver';

const format = 'HH:mm'
const axios = require('axios')


class GenerateAlignment extends React.Component {

	constructor(props) {
		super(props);
		
		this.state = {
			loading: false,
			iconLoading: false,
			open: false,
			providers: [],
			completedProviders: [],
			incompleteProviders: [],
			bride:"",
			groom:"",
			ceremonyTime: undefined,
			cocktailTime: undefined,
			dinnerTime: undefined,
			cakeTime: undefined,
			firstDance: undefined,
			bouquetTime: undefined,
			supperTime: undefined
		}
	}

	componentDidMount = () => {
		let id = this.props.match.params.id;
		
		//Get Wed Info
		axios.get( API_URL + '/wedding/getWedding/' + id , { headers: {'authorization': this.props.tokens} })
		.then((response) => {
			let weddingRes = response.data.wedding
			
			this.setState({
				wed_id: weddingRes._id,
				groom: weddingRes.groom.nameGroom,
				bride: weddingRes.bride.nameBride
			})
		})
		.catch((error) => {
			console.log(error);
		});

		//Get Wanted Providers
		axios.get( API_URL + '/wedding/providersWanted/' + id , { headers: {'authorization': this.props.tokens} })
		.then((response) => {
			//console.log("RESPONSE DATA PROVIDERS-Axios: ", response.data.providers)

			this.setState({
				providers: response.data.providers,
				addressBride: response.data.wedInfo.brideAddress,
				addressGroom: response.data.wedInfo.groomAddress,
				ceremonyLocal: response.data.wedInfo.ceremonyLocation,
				coupleNotes: response.data.wedInfo.coupleObs,
				reception: response.data.wedInfo.receptionLocation,
				ceremonyTime: response.data.wedInfo.ceremonyTime,
				cocktailTime: response.data.wedInfo.cocktailTime,
				dinnerTime: response.data.wedInfo.dinnerTime,
				cakeTime: response.data.wedInfo.cakeTime,
				firstDance: response.data.wedInfo.firstDanceTime,
				bouquetTime: response.data.wedInfo.bouquetTime,
				supperTime: response.data.wedInfo.supperTime,
				generalNotes: response.data.wedInfo.teamNotes
			})
		})
		.catch((error) => {
			console.log(error);
		});

	}

	changeRowEvent = (value, key, index) => {

		//console.log("VALUE- ", value)

		let providersArr = this.state.providers;
		providersArr[index]["provider"][key] = value;
		this.setState({
			providers: providersArr
		})
		console.log("AAAAAAAAA", providersArr)
	}


	changeFormValue = (key, value) => {
        this.setState({
            [key]: value
        })
        console.log("BBBBBB", this.state)
	}
	
	updateAlignment = () => {

		let id = this.props.match.params.id;
		let provObjArr = []

		//console.log("PROVIDERS BEFORE UPDATE: ", this.state.providers)

		this.state.providers.forEach(value => {

			let obj
			console.log("VALUE: ", value)

			if(value.provider.providerId === null || value.provider.providerId === undefined) {
				obj = {
					role: value.provider.role,
					startTime: value.provider.startTime,
					endTime: value.provider.endTime,
					notes: value.provider.notes,
					_id: value._id
				}
			} else {
				obj = {
					providerId: value.provider.providerId._id,
					role: value.provider.role,
					startTime: value.provider.startTime,
					endTime: value.provider.endTime,
					notes: value.provider.notes,
					_id: value._id
				}
			}

			console.log("OBJ BEFORE PUSH: ", obj)

			provObjArr.push(obj)
		});

			//console.log("PROVOBJARR: ", provObjArr)


        axios.put( API_URL + '/wedding/updateTime/' + id, provObjArr, { headers: {Authorization: this.props.tokens}})
        .then((response) => {
            console.log("Alinhamento atualizado com sucesso!");
            // this.setState({ loading: false})
            notify("Alinhamento atualizado com sucesso!", "success")
            // this.props.history.push('/wedding_details/' + id)
        })
        .catch((err) => {
            notify("Erro ao atualizar alinhamento.")
            console.dir(err);
            this.setState({ loading: false });   
		});
		
		let wedObj

		wedObj = {
			brideAddress: this.state.addressBride,
			groomAddress: this.state.addressGroom,
			coupleObs: this.state.coupleNotes,
			ceremonyTime: this.state.ceremonyTime,
			ceremonyLocation: this.state.ceremonyLocal,
			receptionLocation: this.state.reception,
			cocktailTime: this.state.cocktailTime,
			dinnerTime: this.state.dinnerTime,
			cakeTime: this.state.cakeTime,
			firstDanceTime: this.state.firstDance,
			bouquetTime: this.state.bouquetTime,
			supperTime: this.state.supperTime,
			teamNotes: this.state.generalNotes
		}

		axios.put( API_URL + '/wedding/updateWedInfo/' + id, wedObj, { headers: {Authorization: this.props.tokens}})
        .then((response) => {
            //console.log("UPDATED TIMES!", response.data);
            this.setState({ loading: false})
            //notify("Alinhamento atualizado com sucesso!", "success")
            this.props.history.push('/wedding_details/' + id)
        })
        .catch((err) => {
            notify("Erro ao atualizar alinhamento.")
            console.dir(err);
            this.setState({ loading: false });   
        });
	}
	
	generateAlignment = () => {
		let id = this.props.match.params.id;

		axios.post(API_URL + '/alignment/createAlignment/' + id, null, { headers: { Authorization: this.props.tokens } })
		.then(res1 => {
			axios.get(API_URL + '/alignment/getAlignment', { headers: { Authorization: this.props.tokens }, responseType: 'blob', params: null })
			.then(res2 => {
			  const pdfBlob = new Blob([res2.data], { type: 'application/pdf' });
			  //console.log("bro - ", res2)
			  saveAs(pdfBlob, 'Alignment.pdf');
			  notify("Alinhamento gerado com sucesso!", "success")
			}).catch(err => {
			  //console.log("DOWNLOAD FAILED")
			  notify("Erro ao gerar alinhamento.")
			})
		  }).catch(err => {
			console.log("INCOMPLETE DATA");
			notify("Para gerar alinhamento são necessários fornecedores e horas para todas as categorias.")
		  });
	}

	render() {
		let providersData = this.state.providers.map((value, index) => {
			let auxEConverted 
			let auxSConverted

			if(value.provider.startTime === undefined || value.provider.startTime === null){
				auxSConverted = undefined
			} else {
				auxSConverted = moment(value.provider.startTime)
			}

			if(value.provider.endTime === undefined || value.provider.endTime === null){
				auxEConverted = undefined
			} else {
				auxEConverted = moment(value.provider.endTime)
			}

			if(value.provider.providerId !== undefined && value.provider.providerId !== null){
				return (
					<Row style={{ marginBottom:"1%", width:"100%", textAlign:"left"}}>
						<Col sm="2">
							<Row>
								<p style={{marginBottom:"1%"}}>{value.provider.providerId.name}</p>
							</Row>
							{(value.pack !== undefined && value.pack !== null) ? (
								<Row>
									<p style={{fontSize:"12px", marginTop:"1%"}}>{value.pack}</p>
								</Row>
							) : (
								<Row>
									<p style={{fontSize:"12px", marginTop:"1%", color:"#cecece"}}>Sem pack selecionado.</p>
								</Row>
							)}
						</Col>
						<Col sm="2">
							<p>{value.provider.role}</p>
						</Col>
						<Col sm="2">
							<TimePicker value={auxSConverted} placeholder="Selecione hora" style={{ width: "75%" }} format={format} minuteStep={15} onChange={(valor) => { this.changeRowEvent(valor, "startTime", index) }} />
						</Col>
						<Col sm="2">
							<TimePicker value={auxEConverted} placeholder="Selecione hora" style={{ width: "75%" }} format={format} minuteStep={15} onChange={(valor) => { this.changeRowEvent(valor, "endTime", index) }} />
						</Col>
						<Col sm="4">
							<FormTextarea value={value.provider.notes} size="sm" style={{height:"40px"}} onChange={(el) => { this.changeRowEvent(el.target.value, "notes", index) }}/>
						</Col>
					</Row>
				) 	
			} else {
				return (
					<Row style={{ marginBottom:"1%", width:"100%", textAlign:"left"}}>
						<Col sm="2">
							<Row>
								<p style={{marginBottom:"1%", color:"#cecece", fontSize:"12px"}}>Sem fornecedor selecionado.</p>
							</Row>
							<Row>
							</Row>
						</Col>
						<Col sm="2">
							<p>{value.provider.role}</p>
						</Col>
						<Col sm="2">
							<TimePicker value={auxSConverted} placeholder="Selecione hora" style={{ width: "75%" }} format={format} minuteStep={15} onChange={(valor) => { this.changeRowEvent(valor, "startTime", index) }} />
						</Col>
						<Col sm="2">
							<TimePicker value={auxEConverted} placeholder="Selecione hora" style={{ width: "75%" }} format={format} minuteStep={15} onChange={(valor) => { this.changeRowEvent(valor, "endTime", index) }} />
						</Col>
						<Col sm="4">
							<FormTextarea value={value.notes} size="sm" style={{height:"40px"}} onChange={(el) => { this.changeRowEvent(el.target.value, "notes", index) }}/>
						</Col>
					</Row>
				)
			}   
		})

		let ceremonyTimeConv
		let cocktailTimeConv
		let dinnerTimeConv
		let cakeTimeConv
		let firstDanceConv
		let bouquetTimeConv
		let supperTimeConv

		if(this.state.ceremonyTime === undefined || this.state.ceremonyTime === null){
			ceremonyTimeConv = undefined
		} else {
			ceremonyTimeConv = moment(this.state.ceremonyTime)
		}

		if(this.state.cocktailTime === undefined || this.state.cocktailTime === null){
			cocktailTimeConv = undefined
		} else {
			cocktailTimeConv = moment(this.state.cocktailTime)
		}

		if(this.state.dinnerTime === undefined || this.state.dinnerTime === null){
			dinnerTimeConv = undefined
		} else {
			dinnerTimeConv = moment(this.state.dinnerTime)
		}

		if(this.state.cakeTime === undefined || this.state.cakeTime === null){
			cakeTimeConv = undefined
		} else {
			cakeTimeConv = moment(this.state.cakeTime)
		}

		if(this.state.firstDance === undefined || this.state.firstDance === null){
			firstDanceConv = undefined
		} else {
			firstDanceConv = moment(this.state.firstDance)
		}

		if(this.state.bouquetTime === undefined || this.state.bouquetTime === null){
			bouquetTimeConv = undefined
		} else {
			bouquetTimeConv = moment(this.state.bouquetTime)
		}

		if(this.state.supperTime === undefined || this.state.supperTime === null){
			supperTimeConv = undefined
		} else {
			supperTimeConv = moment(this.state.supperTime)
		}
		
		return (
			<div className="general_div">
				<Row>
					<Col sm="1" style={{maxWidth:"4%"}} className="inline-col">
						<h4 className="pointer" style={{ marginLeft: "50%", marginBottom: "2%", marginTop:"13px" }}><ArrowBack style={{fontSize:"18px"}} onClick={() => { this.props.history.push('/wedding_details/' + this.state.wed_id)}}/></h4>
					</Col>
					<Col sm="11">
						<h5 style={{color:"#66CCE4", marginBottom:"1%"}}><b>Alinhamento Geral</b></h5>
						<p style={{color:"#555555", marginBottom:"4%", textAlign:"center"}}><b>{this.state.bride} & {this.state.groom}</b></p>
					</Col>
				</Row>
				<Row style={{textAlign:"left", maxWidth:"96%", marginLeft:"-3%", marginTop:"1%"}}>
					<p style={{ marginLeft: 0, fontSize:"15px", fontWeight:"bold"}}>Informações Adicionais</p>
				</Row>
				<Row style={{textAlign:"left", marginBottom:"2%", maxWidth:"96%", marginLeft:"-4%"}}>
					<Col sm="6">
						<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"14px"}}>Morada da Noiva</p>
						<Input value={this.state.addressBride} onChange={(el) => { this.changeFormValue("addressBride", el.target.value) }} />
					</Col>
					<Col sm="6">
						<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"14px"}}>Morada do Noivo</p>
						<Input value={this.state.addressGroom} onChange={(el) => { this.changeFormValue("addressGroom", el.target.value) }} />
					</Col>
				</Row>
				<Row style={{textAlign:"left", marginBottom:"2%", maxWidth:"96%", marginLeft:"-4%"}}>
					<Col sm="6">
						<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"14px"}}>Local da Cerimónia</p>
						<Input value={this.state.ceremonyLocal} onChange={(el) => { this.changeFormValue("ceremonyLocal", el.target.value) }} />
					</Col>
					<Col sm="6">
						<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"14px"}}>Local do Copo de Água</p>
						<Input value={this.state.reception} onChange={(el) => { this.changeFormValue("reception", el.target.value) }} />
					</Col>
				</Row>
				<Row style={{textAlign:"left", maxWidth:"96%", marginLeft:"-3%"}}>
					<p style={{ marginLeft: 0, fontSize:"15px", fontWeight:"bold"}}>Horários</p>
				</Row>
				<Row style={{textAlign:"left", maxWidth:"96%"}}>
					<Col style={{paddingRight:"0px", paddingLeft:"0px"}}>
						<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"14px"}}>Cerimónia</p>
						<TimePicker value={ceremonyTimeConv} placeholder="Selecione hora" style={{ width: "75%" }} format={format} minuteStep={15} onChange={(valor) => { this.changeFormValue("ceremonyTime", valor) }}/>
					</Col>
					<Col style={{paddingRight:"0px", paddingLeft:"0px"}}>
						<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"14px"}}>Cocktail</p>
						<TimePicker value={cocktailTimeConv} placeholder="Selecione hora" style={{ width: "75%" }} format={format} minuteStep={15} onChange={(valor) => { this.changeFormValue("cocktailTime", valor) }}/>
					</Col>
					<Col style={{paddingRight:"0px", paddingLeft:"0px"}}>
						<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"14px"}}>Jantar</p>
						<TimePicker value={dinnerTimeConv} placeholder="Selecione hora" style={{ width: "75%" }} format={format} minuteStep={15} onChange={(valor) => { this.changeFormValue("dinnerTime", valor) }}/>
					</Col>
					<Col style={{paddingRight:"0px", paddingLeft:"0px"}}>
						<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"14px"}}>Corte do Bolo</p>
						<TimePicker value={cakeTimeConv} placeholder="Selecione hora" style={{ width: "75%" }} format={format} minuteStep={15} onChange={(valor) => { this.changeFormValue("cakeTime", valor) }}/>
					</Col>
					<Col style={{paddingRight:"0px", paddingLeft:"0px"}}>
						<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"14px"}}>1ª dança</p>
						<TimePicker value={firstDanceConv} placeholder="Selecione hora" style={{ width: "75%" }} format={format} minuteStep={15} onChange={(valor) => { this.changeFormValue("firstDance", valor) }}/>
					</Col>
					<Col style={{paddingRight:"0px", paddingLeft:"0px"}}>
						<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"14px"}}>Lançamento do Bouquet</p>
						<TimePicker value={bouquetTimeConv} placeholder="Selecione hora" style={{ width: "75%" }} format={format} minuteStep={15} onChange={(valor) => { this.changeFormValue("bouquetTime", valor) }}/>
					</Col>
					<Col style={{paddingRight:"0px", paddingLeft:"0px"}}>
						<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"14px"}}>Ceia</p>
						<TimePicker value={supperTimeConv} placeholder="Selecione hora" style={{ width: "75%" }} format={format} minuteStep={15} onChange={(valor) => { this.changeFormValue("supperTime", valor) }}/>
					</Col>
				</Row>
				<Row style={{textAlign:"left", maxWidth:"96%", marginLeft:"-3%", marginTop:"3%", marginBottom:"2%"}}>
					<p style={{ marginLeft: 0, fontSize:"15px", fontWeight:"bold"}}>Observações sobre o casal</p>
					<FormTextarea value={this.state.coupleNotes} style={{height:"90px"}} onChange={(el) => { this.changeFormValue("coupleNotes", el.target.value) }}/>
				</Row>
				<Row style={{textAlign:"left", maxWidth:"96%", marginLeft:"-3%", marginTop:"2%", marginBottom:"4%"}}>
					<p style={{ marginLeft: 0, fontSize:"15px", fontWeight:"bold"}}>Notas da equipa</p>
					<FormTextarea value={this.state.generalNotes} style={{height:"90px"}} onChange={(el) => { this.changeFormValue("generalNotes", el.target.value) }}/>
				</Row>
				<Row style={{width:"100%", textAlign:"left", marginBottom:"2%"}}>
					<Col sm="2">
						<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold"}}>Fornecedor</p>
					</Col>
					<Col sm="2">
						<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold"}}>Categoria</p>
					</Col>
					<Col sm="2">
						<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold"}}>Início</p>
					</Col>
					<Col sm="2">
						<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold"}}>Fim</p>
					</Col>
					<Col sm="4">
						<p style={{ marginLeft: 0, marginBottom:"5px", fontSize:"15px", fontWeight:"bold"}}>Notas</p>
					</Col>
				</Row>
				<Row style={{width:"95%", textAlign:"left", marginBottom:"4%"}}>
					{providersData}
				</Row>
				<Row style={{width:"95%", textAlign:"right", marginBottom:"4%", justifyContent:"flex-end"}}>
					<Col>
						<Button className="btn-user" onClick={this.updateAlignment}>Atualizar</Button>
					</Col>
					<Col sm="1">
					<Button className="btn-user" onClick={this.generateAlignment}>Gerar</Button>
					</Col>
				</Row>
			</div>
		);
	} 
}

let appContainer = withRouter(GenerateAlignment);

  function mapStateToProps(state) {
	return { tokens: state.tokens, reduxStorageLoaded: state.storageLoaded, validTokens: state.validTokens, _id: state.user._id }
  }

  const AppRedux = connect(
	mapStateToProps,
  )(appContainer);
  
export default AppRedux;
