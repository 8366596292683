import React from 'react'
import '../App.css'
import {
	Col,
	Row,
	Button,
	FormTextarea
} from "shards-react"
import { Input, TimePicker, DatePicker } from 'antd'
import { connect } from "react-redux"
import { withRouter } from "react-router"
import { API_URL } from '../configs/configs'
import queryString from 'query-string'
import moment from 'moment'
import { after as _after } from 'lodash'
import notify from '../helpers/notify'



const axios = require('axios')
const DateInput = Input.Group
const format = 'HH:mm'


class EditMeeting extends React.Component {

	constructor(props) {
		super(props);
		
		this.state = {
			loading: false,
			iconLoading: false,
			wedding: [],
			meeting: null
		}
	}

	componentDidMount = () => {
		const url = queryString.parse(this.props.location.search)

        //GET URL PARAMS
        let meetingUrl = url.meeting;
		let wedUrl = url.wed;
	
		let step = _after(2, () => {
            this.getInfo()
        })
	
		axios.get( API_URL + '/wedding/getWedding/' + wedUrl , { headers: {'authorization': this.props.tokens} })
		.then((response) => {
			//console.log("RESPONSE DATA WEDDING BY ID", response.data.wedding);
			let weddingRes = response.data.wedding
			let weddingData =	{
				groom: weddingRes.groom.nameGroom,
				bride: weddingRes.bride.nameBride
			}
			this.setState({
				wedding: weddingData
			}, step)
		})
		.catch((error) => {
			console.log(error);
			step()
		});


		axios.get( API_URL + '/wedding/getMeeting?wedId=' + wedUrl + '&meetingId=' + meetingUrl , { headers: {'authorization': this.props.tokens} })
		.then((response) => {
			//console.log("RESPONSE DATA MEETINGS BY ID", response.data);
			let meeting = response.data.meeting

			let meetingInfo = {
				subject: meeting.subject,
				date: moment(meeting.date),
				time: meeting.time,
				local: meeting.location,
				notes: meeting.notes
			}
			this.setState({
				meeting: meetingInfo
			}, step)
		})
		.catch((error) => {
			console.log(error);
			step()
		});

	}

	getInfo = () => {
		let auxConverted
		if(this.state.meeting.time === undefined || this.state.meeting.time === null){
			auxConverted = undefined
		} else {
			auxConverted = moment(this.state.meeting.time)
		}

		this.setState({
			convertedDate: moment(this.state.meeting.date),
			convertedTime: auxConverted
		})

		console.log("STATE: ", this.state.convertedDate)
	}


	changeFormValue = (key, value) => {
		this.setState({
			meeting: {
				[key]:value
			}
		})
		//console.log("BBBBBB", this.state)
	}

	changeFormValue2 = (key, value) => {
		this.setState({
			[key]:value
		})
		//console.log("BBBBBB", this.state)
	}

    changeRowEvent = (key, value) => {
        let meetingArr = this.state.meeting;
        meetingArr[key] = value;
        this.setState({
            meeting: meetingArr
        })
        //console.log("AAAAAAAAA", meetingArr)
	}

	updateMeeting = () => {
		const url = queryString.parse(this.props.location.search)

        //GET URL PARAMS
        let meetingUrl = url.meeting;
		let wedUrl = url.wed;

		let params = {
			wedId: wedUrl,
			meetingId: meetingUrl
		}

		let meetObj = {
			subject: this.state.meeting.subject,
			date: this.state.convertedDate,
			time: this.state.convertedTime,
			location: this.state.meeting.local,
			notes: this.state.meeting.notes
		}

        axios.put( API_URL + '/wedding/updateMeeting' , meetObj, { headers: {Authorization: this.props.tokens}, params: params})
        .then((response) => {
            //console.log(response.data);
            this.setState({ 
				loading: false,
			})
			notify("Reunião editada com sucesso!", "success")
			this.props.history.push('/meetings/' + wedUrl)
        })
        .catch((err) => {
            notify("Erro ao editar reunião.")
            console.log(err);
            this.setState({ loading: false });
		});
	}
	

	render() {
		if(this.state.meeting === null){
			return(
				<div></div>
			)
		} else {
			return (
				<div className="general_div">
					<h5 style={{color:"#66CCE4", marginBottom:"1%"}}><b>Reuniões</b></h5>
					<p style={{color:"#555555", marginBottom:"4%", textAlign:"center"}}><b>{this.state.wedding.bride} & {this.state.wedding.groom}</b></p>
					<Row style={{width:"95%", textAlign:"left", marginBottom:"2%", marginTop:"4%"}}>
						<Col sm="3">
							<Input value={this.state.meeting.subject} placeholder="Assunto" onChange={(el) => { this.changeRowEvent("subject", el.target.value) }} />
						</Col>
						<Col sm="2">
							{(this.state.convertedDate !== null && this.state.convertedDate !== undefined) ? (
								<DateInput>
									<DatePicker value={this.state.convertedDate} type="date" placeholder="Selecione data" style={{ width: '100%' }} onChange={(val) => { this.changeFormValue2("convertedDate", val) }} />
								</DateInput>
							) : (
								<DateInput>
									<DatePicker stype="date" placeholder="Selecione data" style={{ width: '100%' }} onChange={(val) => { this.changeFormValue2("convertedDate", val) }} />
								</DateInput>
							)}
						</Col>
						<Col sm="2">
							<TimePicker value={this.state.convertedTime} placeholder="Selecione hora" style={{ width: "75%" }} format={format} minuteStep={15} onChange={(val) => { this.changeFormValue2("convertedTime", val) }} />
						</Col>
						<Col sm="2">
							<Input value={this.state.meeting.local} placeholder="Local" onChange={(el) => { this.changeRowEvent("local", el.target.value) }}/>
						</Col>
						<Col sm="3">
							<FormTextarea value={this.state.meeting.notes} size="sm" placeholder="Notas" style={{height:"32px", width: "100%"}} onChange={(el) => { this.changeRowEvent("notes", el.target.value) }}/>
						</Col>
					</Row>
					<Row style={{width:"95%", textAlign:"right", marginBottom:"4%", justifyContent:"flex-end"}}>
						<Button className="btn-inverted" onClick={this.updateMeeting}>Atualizar</Button>
					</Row>
				</div>
			);
		}
	} 
}

let appContainer = withRouter(EditMeeting);

  function mapStateToProps(state) {
	return { tokens: state.tokens, reduxStorageLoaded: state.storageLoaded, validTokens: state.validTokens, _id: state.user._id }
  }

  const AppRedux = connect(
	mapStateToProps,
  )(appContainer);
  
export default AppRedux;
